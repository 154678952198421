
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?260386#fontello') format('svg');
  }
}
*/

 [class^="icon-sm-"]:before, [class*=" icon-sm-"]:before {

  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-sm-facebook:before { content: 'f'; } /* '' */
.icon-sm-mail-squared:before { content: '\f199'; } /* '' */
.sm-icon{
	display: inline-block;
  background:#ffffff;
  
  padding:3px 0px 3px 4px;
  margin-right: 1em;
  margin-bottom: 1em;

}
  .sidr .sm-icon{
    background:none;
    font-size:30px;
    margin:0;
    padding:0 15px;
  }
  .sidr  [class^="icon-sm-"]:before, .sidr  [class*=" icon-sm-"]:before {
    margin-left:0;
  }
  .sidr .socialmedia a{
    display: inline-block;
  }
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}
.socialmedia{
  padding-right:0;
}
.primary .nav li.socialmedia a::after{
  display: none;
}

.primary .nav li.socialmedia a:hover{
  color: #3b5998;
}
